import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const louangealamort = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Louange à la mort" />
    <h3 className='underline-title'>Louange à la mort</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Ô mort<br />
        Seule tu es juste<br />
        Ta justice n'est pas celle des animaux pestiférés<br />
        Où un loup prouva par harangue<br />
        Qu'il fallait dévouer le tondeur d'occasion d'un pré<br />
        Tu ignores et Kennedy et Mbérékété<br />
      </p>
      <p>
        Ô mort<br />
        Juste tu l'es<br />
        Quelle destinée ne raboteras-tu<br />
        L'acteur se vautre dans mille actions<br />
        Malin alibi à ton sentiment<br />
        Pour dissiper provisoirement<br />
        L'angoisse que tu filtres au coeur<br />
        L'opulent a beau faire bombance<br />
        Pour te mettre au rancart<br />
        Tu le suces comme sangsue<br />
        </p>
        <p>
        Ô destin<br />
        Tu es là!<br />
        Toujours-là constamment là!<br />
        Tu es encore là<br />
        Par indigestion apres les festins<br />
        Par lumbago après les bals<br />
        Tu surviens par n'importe quelle issue<br />
        </p>
        <p>
        Ô mort<br />
        Omniprésente<br />
        Pour le <span className="black-bold">vaut-rien</span><br />
        Pour le <span className="black-bold">vaut-quelque-chose</span><br />
        Mystérieuse<br />
        Tant qu'on est là tu n'es pas<br />
        Lorsque tu adviens on n'est pas<br />
        </p>
        <p>
        Ô mort<br />
        Ta justice est juste.<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default louangealamort
